import { html, render, nothing } from "lit-html"
import { virtual, useState, useEffect } from "haunted"
import { AutocompleteText } from "../inputs"
import { GEOCODE_ENDPOINT, getStateAbbrev, onResults } from "./geocode"

const DEFAULT_CUSTOM_LOCATION_FORM = {
  custom_location: false,
  in_person: true,
  remote: true,
  link: "",
  location: {
    name: "",
    street_address: "",
    address2: "",
    city: "",
    state: "",
    zip_code: "",
  },
  errors: { location: {} },
}
let STATE_OPTIONS = nothing
let STATE_VALUE = ""
const noop = () => {}

const FieldWrapper = ({ label, field }) => {
  return html`
    <div class="field-wrapper flex py-4 flex-wrap md:flex-nowrap">
      <label class="mb-4 md:mb-0 w-full md:w-1/4 md:pr-4 font-bold">
        ${label}
      </label>
      <div class="w-full md:w-3/4">${field}</div>
    </div>
  `
}

const LinkFieldWrapper = virtual(
  ({ label, field, form = DEFAULT_CUSTOM_LOCATION_FORM, onChange = noop }) => {
    const [linkErr, setLinkErr] = useState(
      field ? field.querySelector(".field-error")?.innerText : form.errors.link
    )
    const validateLink = (value) => {
      if (value === "") {
        setLinkErr("")
        return
      }
      try {
        new URL(value)
        setLinkErr("")
      } catch {
        setLinkErr("Please enter a valid URL.")
      }
    }

    const name = "link"
    const value = field
      ? field.querySelector("input").value
      : form
      ? form.link
      : ""
    return html`
      <div class="field-wrapper flex py-4 flex-wrap md:flex-nowrap">
        <label class="mb-4 md:mb-0 w-full md:w-1/4 md:pr-4 font-bold">
          ${label}
        </label>
        <div class="w-full md:w-3/4">
          <input
            name="${name}"
            type="url"
            .value=${value}
            ?form=${!field}
            @change="${(e) => {
              const value = e.target.value
              onChange("link", value)
              validateLink(value)
            }}}"
          />
        </div>
      </div>
      ${linkErr
        ? FieldWrapper({
            name: "link-error",
            label: "",
            field: html`
              <span class="block -mt-5 text-red text-sm field-error">
                ${linkErr}
              </span>
            `,
          })
        : nothing}
    `
  }
)

const selectStateOptions = (selectedValue = STATE_VALUE) =>
  Array.from(STATE_OPTIONS).map(
    (option) => html`
      <option
        value="${option.value}"
        ?selected=${option.value === selectedValue}
      >
        ${option.text}
      </option>
    `
  )

const StateFieldWrapper = ({
  label,
  onChangeState = () => {},
  form = null,
}) => html`
  <div class="field-wrapper flex py-4 flex-wrap md:flex-nowrap">
    <label class="mb-4 md:mb-0 w-full md:w-1/4 md:pr-4 font-bold">
      ${label}
    </label>
    <div class="w-full md:w-3/4">
      <select
        class="input"
        name="state"
        ?form=${!!form}
        @change=${onChangeState}
      >
        ${selectStateOptions(form?.location.state)}
      </select>
    </div>
  </div>
`

export const LocationFieldsWrappers = virtual(
  ({
    key = "",
    isInPerson = false,
    isRemote = false,
    fields,
    setFormFieldValue = () => {},
    stateData = {
      options: [],
      value: "",
    },
  }) => {
    const [inPerson, setInPerson] = useState(isInPerson)
    const [remote, setRemote] = useState(isRemote)

    useEffect(() => {
      STATE_OPTIONS = stateData.options
      STATE_VALUE = stateData.value
    }, [])

    useEffect(() => {
      setFormFieldValue("in_person", inPerson)
    }, [inPerson])

    useEffect(() => {
      setFormFieldValue("remote", remote)
    }, [remote])

    return html`
      <div class="field-wrapper flex py-8 flex-wrap md:flex-nowrap">
        <label class="mb-4 md:mb-0 w-full md:w-1/4 md:pr-4 font-bold">
          Event type
        </label>
        <div class="mr-10 flex items-center">
          <input
            type="checkbox"
            name="in_person"
            id="id${key}_in_person"
            .checked=${inPerson}
            @click=${() => setInPerson(!inPerson)}
          />
          <label for="id${key}_in_person" class="ml-3">In-person event</label>
        </div>
        <div class="flex items-center">
          <input
            type="checkbox"
            name="remote"
            id="id${key}_remote"
            .checked=${remote}
            @click=${() => setRemote(!remote)}
          />
          <label for="id${key}_remote" class="ml-3">Remote event</label>
        </div>
      </div>
      <div class="${remote ? "block" : "hidden"}">
        ${LinkFieldWrapper(fields.link)}
      </div>
      <div class="${inPerson ? "block" : "hidden"}">
        ${FieldWrapper(fields.location_name)}
        ${FieldWrapper(fields.street_address)} ${FieldWrapper(fields.address2)}
        ${FieldWrapper(fields.city)} ${StateFieldWrapper(fields.state)}
        ${FieldWrapper(fields.zip_code)}
      </div>
    `
  }
)

export const CustomLocationForm = virtual(
  ({
    key,
    form = DEFAULT_CUSTOM_LOCATION_FORM,
    setFormFieldValue,
    stateData = {
      options: [],
      value: "",
    },
  }) => {
    const [customLocation, setCustomLocation] = useState(form.custom_location)

    useEffect(() => {
      STATE_OPTIONS = stateData.options
      STATE_VALUE = stateData.value
    }, [])

    useEffect(() => {
      setFormFieldValue("custom_location", customLocation)
    }, [customLocation])

    const customLocationFormField = (name, label, type = "text") => {
      const [locationErr, setLocationErr] = useState(
        form.errors.location[name] || ``
      )
      const onChangeLocationField = (e) => {
        setFormFieldValue(name, e.target.value, true)
        setLocationErr(``)
      }
      return {
        name,
        label,
        field: html`
          <input
            name="${name}"
            form="custom-location-form"
            type="${type}"
            .value=${form.location[name]}
            @change=${onChangeLocationField}
          />
          ${locationErr
            ? html`
                <span class="block text-red text-sm field-error"
                  >${locationErr}</span
                >
              `
            : nothing}
        `,
      }
    }

    const changeAddressFieldValue = (name, value) => {
      form.location[name] = value
      setFormFieldValue(name, value, true)
    }

    const onStreetAddressSelect = (address) => {
      const {
        addressLine: street = "",
        adminDistrict: state = "",
        locality: city = "",
        postalCode: zip = "",
      } = address
      changeAddressFieldValue("street_address", street)
      changeAddressFieldValue("state", getStateAbbrev(state))
      changeAddressFieldValue("city", city)
      changeAddressFieldValue("zip_code", zip)
    }

    return html`
      <div class="field-wrapper flex pt-6 pb-4 flex-wrap md:flex-nowrap">
        <label class="md:mb-0 w-full md:w-1/4 md:pr-4 font-bold">
          Custom location
        </label>
        <div class="w-full md:w-3/4 pt-2">
          <span class="button-toggle">
            <input
              type="checkbox"
              class="toggle"
              name="custom-location"
              form="custom-location-form"
              .checked=${customLocation}
              @click=${() => setCustomLocation(!customLocation)}
            />
          </span>
          <p class="help">
            Add new event location if different from the original event
          </p>
        </div>
      </div>
      <div
        class="border-px border-gray-border rounded-md px-6 py-5 ml-4 mb-4 ${customLocation
          ? ""
          : "hidden"}"
      >
        <label class="mt-2 text-gray-450 text-sm">
          CUSTOM LOCATION DETAILS
        </label>
        ${LocationFieldsWrappers({
          key,
          setFormFieldValue,
          isInPerson: form.in_person,
          isRemote: form.remote,
          fields: {
            link: {
              label: "Event link",
              form,
              onChange: setFormFieldValue,
            },
            location_name: customLocationFormField("name", "Location name"),
            street_address: {
              name: "street_address",
              label: "Street name",
              field: AutocompleteText({
                form: "custom-location-form",
                name: "street_address",
                required: false,
                value: form.location.street_address || "",
                placeholder: ``,
                endpoint: GEOCODE_ENDPOINT,
                onResults,
                onSelect: onStreetAddressSelect,
              }),
            },
            address2: customLocationFormField("address2", "Address 2"),
            city: customLocationFormField("city", "City"),
            state: {
              form,
              label: "State",
              onChangeState: (e) => {
                setFormFieldValue("state", e.target.value, true)
              },
            },
            zip_code: customLocationFormField("zip_code", "Zip code"),
          },
          stateData: {
            options: STATE_OPTIONS,
            value: STATE_VALUE,
          },
        })}
      </div>
    `
  }
)

const LocationGroup = ({ fields }) => {
  return html`${LocationFieldsWrappers({
      fields,
      isInPerson: fields.in_person.field.querySelector("input").checked,
      isRemote: fields.remote.field.querySelector("input").checked,
      stateDate: {
        options: STATE_OPTIONS,
        value: STATE_VALUE,
      },
    })}
    <div class="pb-6 border-b-px border-gray-border"></div>`
}

export function renderCustomLocationInput(el) {
  const formEl = el.closest("form")
  const $group = el.closest(`.form-fields-group`)

  // Process fields directly
  const fields = Object.fromEntries(
    [...$group.querySelectorAll(".field-wrapper")].map(($fieldWrapper) => {
      const $label = $fieldWrapper.querySelector(`label`)
      const fieldName = $label.htmlFor.match(/id_(.+)/)[1]
      const $field = $fieldWrapper.querySelector(`.field`)
      return [
        fieldName,
        { name: fieldName, label: $label.textContent.trim(), field: $field },
      ]
    })
  )

  const stateSelect = fields.state.field.querySelector("select")
  STATE_OPTIONS = stateSelect.options
  STATE_VALUE = stateSelect.value

  // Render and replace directly
  const mount = document.createElement("div")
  render(LocationGroup({ fields }), mount)
  $group.replaceWith(...mount.childNodes)

  // Handle form submission directly
  formEl.addEventListener("submit", (e) => {
    e.preventDefault()
    const errors = formEl.querySelectorAll(".field-error")
    let error = null
    errors.forEach((err) => {
      if (!err.closest(".hidden")) {
        error = err
      }
    })

    if (error) {
      error.scrollIntoView({ behavior: "smooth", block: "center" })
    } else {
      e.target.submit()
    }
  })
}
