import { html, nothing, render } from "lit-html"
import { virtual, useState, useEffect } from "haunted"
import { repeat } from "lit-html/directives/repeat"
import { LocationFieldsWrappers, CustomLocationForm } from "../inputs"
import { SelectBox } from "../utils/select-box"
import Modal from "../generic/modal"

const RECURRING = "recurring"
const CUSTOM_DATES = "custom"
const DEFAULT_CUSTOM_DATE_FORM = {
  custom_location: false,
  in_person: true,
  remote: true,
  link: "",
  location: {
    name: "",
    street_address: "",
    address2: "",
    city: "",
    state: "",
    zip_code: "",
  },
  errors: { location: {} },
}
let STATE_OPTIONS = nothing
let STATE_VALUE = ""
let START_TIME_REQUIRED = false
const noop = () => {}

const FieldWrapper = ({ label, field }) => {
  return html`
    <div class="field-wrapper flex py-4 flex-wrap md:flex-nowrap">
      <label class="mb-4 md:mb-0 w-full md:w-1/4 md:pr-4 font-bold">
        ${label}
      </label>
      <div class="w-full md:w-3/4">${field}</div>
    </div>
  `
}

const DateTimeFormField = virtual(
  ({
    name,
    type,
    value,
    form = DEFAULT_CUSTOM_DATE_FORM,
    onChange = noop,
    required = false,
    key = "",
  }) => {
    const [fieldErr, setFieldErr] = useState(!!form.errors[name])
    const [error, setError] = useState(form.errors[name] || ``)

    const onChangeValue = (e) => {
      onChange(e)
      if (required) {
        setFieldErr(!e.target.value)
        setError(`Please enter a valid ${type}`)
      }
    }

    return html`
      <div class="relative">
        <input
          id="id_${name}${key}"
          type="${type}"
          name="${name}"
          ?form=${!!key}
          ?required=${required}
          .value=${value}
          @change=${onChangeValue}
        />
        ${required && fieldErr
          ? html`
              <span class="block mt-2 text-red text-sm field-error">
                ${error}
              </span>
            `
          : nothing}
      </div>
    `
  }
)

const DateTimeFieldWrapper = ({
  label,
  dateField,
  timeField,
  required = false,
}) => {
  return html`
    <div class="field-wrapper flex py-4 flex-wrap md:flex-nowrap">
      <label
        class="mb-4 md:mb-0 w-full md:w-1/4 md:pr-4 font-bold ${required
          ? "required-label"
          : ""}"
      >
        ${label}
      </label>
      <div
        class="${dateField
          ? `inline-block`
          : `hidden`} md:inline-block w-full md:w-3/8 md:pr-5 field"
      >
        ${dateField ? DateTimeFormField(dateField) : nothing}
      </div>
      <div class="w-full md:w-3/8 md:pl-5 field">
        ${DateTimeFormField(timeField)}
      </div>
    </div>
  `
}

const CustomDateLabel = (customIndex = 1) => html`
  <div class="field-wrapper flex py-4 flex-wrap md:flex-nowrap">
    <label class="mt-6 md:mb-0 w-full md:w-1/4 md:pr-4 text-gray-450 text-sm">
      CUSTOM DATE #${customIndex}
    </label>
    <div class="w-full md:w-3/4 border-gray-border border-t-px"></div>
  </div>
`

const CustomDateForm = virtual(
  ({
    key,
    form = DEFAULT_CUSTOM_DATE_FORM,
    customIndex,
    originalFields,
    onRemoveCustomDateForm,
    onUpdateCustomDateForm,
  }) => {
    const [inputCustomDates, setInputCustomDates] = useState(form)

    useEffect(() => {
      onUpdateCustomDateForm(key, { ...inputCustomDates })
    }, [inputCustomDates])

    const setFormFieldValue = (field, value, location = false) => {
      if (location) {
        setInputCustomDates({
          ...inputCustomDates,
          location: {
            ...inputCustomDates.location,
            [field]: value,
          },
        })
      } else {
        setInputCustomDates({
          ...inputCustomDates,
          [field]: value,
        })
      }
    }

    const customDateTimeFormField = (name, type, value) => ({
      key,
      form,
      name,
      type,
      value,
      required: name == "start_time" ? START_TIME_REQUIRED : false,
      onChange: (e) => {
        setFormFieldValue(name, e.target.value)
      },
    })

    return html`
      <div class="relative">
        <input
          name="custom_dates"
          type="hidden"
          .value="${JSON.stringify(inputCustomDates)}"
        />
        <a
          class="absolute right-0 top-20 text-red cursor-pointer"
          @click="${() => {
            onRemoveCustomDateForm(key)
          }}"
        >
          <i class="mr-3 far fa-circle-minus"></i>
          <span class="font-bold">Remove</span>
        </a>
        ${CustomDateLabel(customIndex)}
        ${DateTimeFieldWrapper({
          label: "Start",
          required: true,
          dateField: {
            key,
            form,
            name: originalFields.start_date.name,
            type: "date",
            value: form["start_date"],
            required: true,
            onChange: (e) => {
              setFormFieldValue(originalFields.start_date.name, e.target.value)
            },
          },
          timeField: customDateTimeFormField(
            originalFields.start_time.name,
            "time",
            form["start_time"],
            key
          ),
        })}
        ${DateTimeFieldWrapper({
          label: "End",
          dateField: originalFields.end_date
            ? customDateTimeFormField(
                originalFields.end_date.name,
                "date",
                form["end_date"],
                key
              )
            : null,
          timeField: customDateTimeFormField(
            originalFields.end_time.name,
            "time",
            form["end_time"],
            key
          ),
        })}
        ${CustomLocationForm({
          key,
          form,
          inputCustomDates,
          setFormFieldValue,
          stateData: {
            options: STATE_OPTIONS,
            value: STATE_VALUE,
          },
        })}
      </div>
    `
  }
)

const CustomDateLocationForms = virtual(
  ({
    forms,
    originalFields,
    onRemoveCustomDateForm,
    onAddCustomDateForm,
    onUpdateCustomDateForm,
  }) => {
    return html`
      <div class="custom-dates">
        ${repeat(
          Object.entries(forms),
          ([key, _]) => key, // eslint-disable-line no-unused-vars
          // eslint-disable-next-line no-unused-vars
          ([key, form], index) => html`
            ${CustomDateForm({
              key,
              form,
              customIndex: index + 2,
              originalFields,
              onRemoveCustomDateForm,
              onUpdateCustomDateForm,
            })}
          `
        )}
        <div class="flex py-4 flex-wrap md:flex-nowrap flex-row-reverse">
          <div class="w-full md:w-3/4 border-gray-border border-t-px"></div>
          <a
            @click=${onAddCustomDateForm}
            class="cursor-pointer mt-4 w-full md:w-1/4 md:pr-4"
          >
            <i class="fas fa-plus"></i>
            <span class="font-bold">Add another date</span>
          </a>
        </div>
      </div>
    `
  }
)

const DateLocationFieldsWrapper = virtual(
  ({ fields, occurrenceType, showCustomDateLabel = false }) => {
    const shouldBind =
      fields.start_date.field.querySelector("input")?.value &&
      fields.end_date?.field.querySelector("input")?.value
    const [bindStartEndDateAttempt, setBindStartEndDateAttempt] = useState(
      shouldBind ? 1 : 0
    )

    useEffect(() => {
      if (shouldBind && bindStartEndDateAttempt) {
        const $startDateInput = document.querySelector("input#id_start_date")
        const $endDateInput = document.querySelector("input#id_end_date")
        if ($startDateInput?.value && $endDateInput?.value) {
          const duration =
            new Date($endDateInput.value) - new Date($startDateInput.value)

          $startDateInput.addEventListener("change", (e) => {
            if (new Date($startDateInput.value).toString() != "Invalid Date") {
              const startDate = new Date(e.target.value)
              $endDateInput.value = new Date(startDate.getTime() + duration)
                .toISOString()
                .substring(0, 10)
            }
          })
          setBindStartEndDateAttempt(0)
        } else {
          setBindStartEndDateAttempt(bindStartEndDateAttempt + 1)
        }
      }
    }, [bindStartEndDateAttempt])

    return html`
      ${showCustomDateLabel ? CustomDateLabel() : nothing}
      ${DateTimeFieldWrapper({
        label: "Start",
        required: true,
        dateField: {
          name: fields.start_date.name,
          type: "date",
          value: fields.start_date.field.querySelector("input").value,
          required: true,
        },
        timeField: {
          name: fields.start_time.name,
          type: "time",
          value: fields.start_time.field.querySelector("input").value,
          required: START_TIME_REQUIRED,
        },
      })}
      ${DateTimeFieldWrapper({
        label: "End",
        dateField: fields.end_date
          ? {
              name: fields.end_date.name,
              type: "date",
              value: fields.end_date.field.querySelector("input").value,
            }
          : null,
        timeField: {
          name: fields.end_time.name,
          type: "time",
          value: fields.end_time.field.querySelector("input").value,
        },
      })}
      ${occurrenceType === RECURRING ? fields.patterns.field : nothing}
      ${LocationFieldsWrappers({
        fields,
        isInPerson: fields.in_person.field.querySelector("input").checked,
        isRemote: fields.remote.field.querySelector("input").checked,
        stateData: {
          options: STATE_OPTIONS,
          value: STATE_VALUE,
        },
      })}
    `
  }
)

const OccurrencesGroup = virtual(({ fields }) => {
  const occurrenceTypeProps = fields.occurrence_type
  const occurrenceTypeOptions = Array.from(
    occurrenceTypeProps.field.querySelectorAll("option")
  ).map(($option) => ({
    label: $option.text,
    value: $option.value,
    onClick: () => {
      setOccurrenceType($option.value)
    },
  }))
  const selectedOccurrenceType = occurrenceTypeProps.field.querySelector(
    "select"
  ).value

  let counter = 0
  const customDatesErrors = JSON.parse(
    fields.custom_dates?.field.querySelector("p.text-red")?.innerText || "{}"
  )
  const customDates = fields.custom_dates
    ? [...fields.custom_dates.field.querySelectorAll("option")].map(
        (option, index) => ({
          ...JSON.parse(option.value),
          errors: customDatesErrors[`${index}`] || {},
        })
      )
    : []

  const [occurrenceType, setOccurrenceType] = useState(selectedOccurrenceType)
  const [customDateForms, setCustomDateForms] = useState(
    Object.fromEntries(
      customDates.map((form) => [`key-${Date.now()}-${counter++}`, form])
    )
  )
  const onAddCustomDateForm = () => {
    setCustomDateForms({
      ...customDateForms,
      [`key-${Date.now()}-${counter++}`]: JSON.parse(
        JSON.stringify(DEFAULT_CUSTOM_DATE_FORM)
      ),
    })
  }

  const onRemoveCustomDateForm = (key) => {
    const newForms = { ...customDateForms }
    delete newForms[key]
    setCustomDateForms(newForms)
  }

  const onUpdateCustomDateForm = (key, form) => {
    setCustomDateForms({
      ...customDateForms,
      [key]: form,
    })
  }

  return html`
    ${FieldWrapper(fields.timezone)}
    ${FieldWrapper({
      ...occurrenceTypeProps,
      field: html`
        <input type="hidden" name="occurrence_type" .value=${occurrenceType} />
        ${SelectBox({
          width: "100%",
          options: occurrenceTypeOptions,
          selectedOption: occurrenceTypeOptions.find(
            (option) => option.value === selectedOccurrenceType
          ),
        })}
      `,
    })}
    ${DateLocationFieldsWrapper({
      fields,
      occurrenceType,
      showCustomDateLabel: occurrenceType === CUSTOM_DATES,
    })}
    ${occurrenceType === CUSTOM_DATES
      ? CustomDateLocationForms({
          forms: customDateForms,
          originalFields: fields,
          onAddCustomDateForm,
          onRemoveCustomDateForm,
          onUpdateCustomDateForm,
        })
      : nothing}
    <div class="pb-6 border-b-px border-gray-border"></div>
  `
})

export default function renderOccurrenceTypeInput(el) {
  const formEl = el.closest("form")
  const $group = el.closest(`.form-fields-group`)

  // Process fields directly
  const fields = Object.fromEntries(
    [...$group.querySelectorAll(".field-wrapper")].map(($fieldWrapper) => {
      const $label = $fieldWrapper.querySelector(`label`)
      const fieldName = $label.htmlFor.match(/id_(.+)/)[1]
      const $field = $fieldWrapper.querySelector(`.field`)
      return [
        fieldName,
        { name: fieldName, label: $label.textContent.trim(), field: $field },
      ]
    })
  )

  const stateSelect = fields.state.field.querySelector("select")
  STATE_OPTIONS = stateSelect.options
  STATE_VALUE = stateSelect.value

  fields.timezone = fields.timezone || fields.tz
  fields.start_date = fields.start_date || fields.date
  START_TIME_REQUIRED = fields.start_time.field.querySelector("input").required

  // Render and replace directly
  const mount = document.createElement("div")
  render(OccurrencesGroup({ fields }), mount)
  $group.replaceWith(...mount.childNodes)

  let cancelBtnHandler = null
  const confirmFormId = "recurring-confirm"
  const confirmFormWrapperId = `${confirmFormId}-wrapper`
  const $recurringConfirmButton = formEl.querySelector(
    `#${confirmFormId}-button`
  )
  const $modalMount = document.getElementById("modal-mount")

  const onCloseModal = () => {
    unbindCancelBtn()
    formEl.removeAttribute("id")
    render(nothing, $modalMount)
  }
  const bindCancelBtn = () => {
    cancelBtnHandler = () => {
      onCloseModal()
    }
    const cancelBtn = document.querySelector(
      `#${confirmFormWrapperId} button[type='reset']`
    )
    cancelBtn?.addEventListener("click", cancelBtnHandler)
  }
  const unbindCancelBtn = () => {
    const cancelBtn = document.querySelector(
      `#${confirmFormWrapperId} button[type='reset']`
    )
    cancelBtn?.removeEventListener("click", cancelBtnHandler)
  }

  const showConfirmModal = (formEl) => {
    const confirmModal = Modal({
      onClose: onCloseModal,
      modalBackgroundClass: "modal-background bg-transparent",
      children: html`<div id="${confirmFormWrapperId}"></div>`,
    })
    render(html`${confirmModal}`, $modalMount)
    setTimeout(() => {
      formEl.setAttribute("id", confirmFormId)
      document.querySelector(`#${confirmFormId}-button`).click()
    }, 0)
  }

  // bind outer form submission
  if (document.querySelector("#recurring-form-invalid")) return
  document.addEventListener("htmx:afterSwap", (e) => {
    if (e.detail.target.getAttribute("id") === confirmFormWrapperId) {
      bindCancelBtn()
    }
  })
  formEl.addEventListener("submit", (e) => {
    e.preventDefault()
    const errors = formEl.querySelectorAll(".field-error")
    let error = null
    errors.forEach((err) => {
      if (!err.closest(".hidden")) {
        error = err
      }
    })

    if (error) {
      error.scrollIntoView({ behavior: "smooth", block: "center" })
    } else {
      if ($recurringConfirmButton) {
        const isConfirmed = formEl.getAttribute("id") == confirmFormId
        if (!isConfirmed) {
          showConfirmModal(formEl)
          return
        }
      }
      e.target.submit()
    }
  })
}
